import { Link } from 'react-router-dom'
import { VideoCardProps } from '../types/Video'
import { BASE_URL } from '../utils/fetchApi'
import React from 'react'

const VideoSingleCard: React.FC<VideoCardProps> = ({video}) => {
    return (
        <section className='video-single'>
            <Link to={video.path}>
                <img src={BASE_URL+video.mediaLink} alt={video.title}/>
                <div className="content">
                    <p>{video.title}</p>
                </div>
            </Link>
        </section>
    )
}

export default VideoSingleCard