import { useState, useEffect } from "react";
import Category from "../components/Category";
import Video from "../components/Video";
import { VideoI } from "../types/Video";
import { FetchApi } from "../utils/fetchApi";

import "../css/globals.scss"
import RootLayout from "../common/Layout";
import { useParams } from "react-router";
import PageComponent from "../components/pagination";

const Home: React.FC = () => {
    const [video, setVideo] = useState<VideoI>();
    const {page} = useParams();
  
    useEffect(() => {
      FetchApi(`/${page||""}`).then((data) => {
        setVideo(data);
      }).catch((error) => {
        console.error("Error fetching data:", error);
      })
    }, [page]);
  
    return (
      <>
        <RootLayout/>
        <Category selectedCategory=""/>
        <Video videoData={video?.videoData || []}/>
        <PageComponent currentPage={video?.nowPage || ""} totalPages={video?.lastPage || ""} path=""/>
      </>
    )
}
  
export default Home