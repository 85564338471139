import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from './route/Home';
import VideoPreview from './route/VideoPreview';
import Search from './route/Search';
import Categorys from './route/Category';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/:page",
    element: <Home/>,
  },
  {
    path: "/videos/:id/",
    element: <VideoPreview/>,
  },
  {
    path: "/search/:id",
    element: <Search/>
  },
  {
    path: "/search/:id/:page",
    element: <Search/>
  },
  {
    path: "/categories/:category",
    element: <Categorys/>
  },
  {
    path: "/categories/:category/:page",
    element: <Categorys/>
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
