'use client'
import SearchBar from '../components/SearchBar'
import { Link } from 'react-router-dom'

const Header: React.FC = () => {
    return (
        <header id='header'>
            <div className="container">
                <div className="row">
                    <Link to='/'>
                        <div className="left">
                            <img src='/logo.png' width={130} height={60} alt='Thisvid'/>
                        </div>
                    </Link>
                    <div className="right">
                        <SearchBar />
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header