import axios from 'axios';

export const BASE_URL = 'https://thisvid-api.fdtstudio.org';

export const FetchApi = async (path: string) => {
  const { data } = await axios.get(`${BASE_URL}${path}`);
  return data;
};

export const FetchApiWithHeader = async (path: string) => {
  const { data, headers } = await axios.get(`${BASE_URL}${path}`);
  return {data, headers};
};
