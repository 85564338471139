import { useState, useEffect } from "react";
import Category from "../components/Category";
import Video from "../components/Video";
import { VideoI } from "../types/Video";
import { FetchApi } from "../utils/fetchApi";

import "../css/globals.scss"
import RootLayout from "../common/Layout";
import { useParams } from "react-router";
import PageComponent from "../components/pagination";

const Categorys: React.FC = () => {
    const [video, setVideo] = useState<VideoI>();
    const {category, page} = useParams();
  
    useEffect(() => {
      FetchApi(`/categories/${category}${page===undefined?"":"/"+page+"/"}`).then((data) => {
        setVideo(data);
      }).catch((error) => {
        console.error("Error fetching data:", error);
      })
    }, [category,page]);
  
    return (
      <>
        <RootLayout/>
        <Category selectedCategory={category || ""}/>
        <Video videoData={video?.videoData || []}/>
        <PageComponent currentPage={video?.nowPage || ""} totalPages={video?.lastPage || ""} path={`/categories/${category}`}/>
      </>
    )
}
  
export default Categorys