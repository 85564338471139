'use client'
import { VideoInfo } from '../types/Video';
import { FetchApiWithHeader } from '../utils/fetchApi';
import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player';

import { useParams } from 'react-router-dom';
import RootLayout from '../common/Layout';
// @ts-ignore
//import download from 'downloadjs';
import { Box, Button } from '@mui/material';

const VideoPreview: React.FC = () => {
    const [videoDetail, setVideoDetail] = useState<VideoInfo | null>(null);
    const {id} = useParams();
    //const [ifPorxy, setIfPorxy] = useState<boolean>(false);
    //const [loading, setLoading] = useState<boolean>(false);

    /*const handleDownload = (videoName : string, videoUrl : string) => {
        setLoading(true);
        const videoFileName = videoName
              .replace(/[^a-zA-Z0-9-_ ]/g, '') // 移除所有非字母、数字、下划线和空格的字符
              .trim() // 去掉首尾空格
              .replace(/\s+/g, '_') // 将所有空格替换为下划线
              .toLowerCase(); // 转换为小写
    
        fetch(videoUrl)
          .then(response => response.blob())
          .then(blob => {
            download(blob, videoFileName, 'video/mp4');
            setLoading(false);
          })
          .catch(error => {
            console.error('下载视频文件时出错:', error);
            setLoading(false);
          });
      };*/

    useEffect(() => {
        FetchApiWithHeader(`/videos/${id}/`).then((data) => {
            /*const ispInfo = data.headers['x-visitor-isp'];
            console.log(data.headers);
            if (ispInfo) {
                if (ispInfo.includes('Mobile')) {
                    setIfPorxy(true);
                }
            }*/
            setVideoDetail(data.data);
        }).catch((error) => {
            console.error("Error fetching data:", error);
        });
    }, [id]);

    return (
        <>
        <RootLayout/>
        <section id="slug">
            {videoDetail && (
                <div className="container">
                    <div className="row">
                        <div className="left">
                            <ReactPlayer
                                url={`https://chuncheon-1.fdtstudio.org:62652/video_proxy?url=${btoa(videoDetail.videoUrl)}`}
                                controls
                                className="player"
                            />
                            <h6>{videoDetail?.title}</h6>
                        </div>
                        {/*<Video className="video-detail" videos={videos} />*/}
                    </div>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ borderRadius: '50px' }} // 设置圆角
                            >
                                <i className="fa-solid fa-eye"></i>  &nbsp;{videoDetail?.view} 观看数
                        </Button>
                        <Button
                            variant="contained"
                            color='secondary'
                            sx={{ borderRadius: '50px' }} // 设置圆角
                            href={`https://chuncheon-1.fdtstudio.org:62652/download_proxy?url=${btoa(videoDetail.videoUrl)}&filename=${
                                videoDetail?.title.replace(/[^a-zA-Z0-9-_ ]/g, '') // 移除所有非字母、数字、下划线和空格的字符
                                .trim() // 去掉首尾空格
                                .replace(/\s+/g, '_') // 将所有空格替换为下划线
                                .toLowerCase()}`}
                            >
                                {'下载视频(代理)'}
                        </Button>
                        <Button
                            variant="contained"
                            color='warning'
                            sx={{ borderRadius: '50px' }} // 设置圆角
                            href={videoDetail.videoUrl}
                        >
                                {'下载视频(直连)[右键保存]'}
                        </Button>
                    </Box>
                </div>
            )}
        </section>
        </>
    )
}

export default VideoPreview