import React from 'react';
import { useNavigate } from 'react-router';
import Pagination from '@mui/material/Pagination';
import { styled } from '@mui/material/styles';

interface Page {
  currentPage : string,
  totalPages : string,
  path: string
}

const CustomPagination = styled(Pagination)(({ theme }) => ({
  '.MuiPaginationItem-root': {
    backgroundColor: '#f5f5f5', // 浅色背景色
    color: '#333', // 字体颜色
    '&:hover': {
      backgroundColor: '#e0e0e0', // 悬停时背景色
    },
    '&.Mui-selected': {
      backgroundColor: '#cfcfcf', // 选中时背景色
      color: '#000', // 选中时字体颜色
    },
  },
}));

const PageComponent: React.FC<Page> = ({ currentPage, totalPages, path}) => {
  const current = parseInt(currentPage, 10);
  const total = parseInt(totalPages, 10);
  const navigate = useNavigate();
  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
    <CustomPagination
      page={current}
      count={total}
      variant="outlined"
      color="primary"
      size="large"
      onChange={(_, page) => {navigate(`${path}/${page}`)}}
    />
    </div>
  );
};

export default PageComponent;