import { useState, useEffect } from "react";
import Category from "../components/Category";
import Video from "../components/Video";
import { VideoI } from "../types/Video";
import { FetchApi } from "../utils/fetchApi";

import "../css/globals.scss"
import RootLayout from "../common/Layout";
import { useParams } from "react-router";
import PageComponent from "../components/pagination";

const Search: React.FC = () => {
    const [video, setVideo] = useState<VideoI>();
    const {id} = useParams();
    const {page} = useParams();

    useEffect(() => {
        FetchApi(`/search${page===undefined?"":"/"+page}?query=${id}`).then((data) => {
            setVideo(data);
          }).catch((error) => {
            console.error("Error fetching data:", error);
          })
    }, [id, page])
  
    return (
      <>
        <RootLayout/>
        <Category selectedCategory={""}/>
        <Video videoData={video?.videoData || []}/>
        <PageComponent currentPage={video?.nowPage || ""} totalPages={video?.lastPage || ""} path={`/search/${id}`}/>
      </>
    )
}
  
export default Search