import React from 'react';
import VideoSingleCard from './VideoSingleCard';
import { VideoProps } from '../types/Video';

interface videoProps {
    videoData: Array<VideoProps>;
}

const Video: React.FC<videoProps> = ({ videoData }) => {
    return (
        <section id='video'>
            <div className="container">
                <div className='row'>
                    {videoData?.map((item: any, idx: number) => (
                        <VideoSingleCard video={item} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Video;
